html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  overflow: hidden;
  position: fixed;
  font-family: 'Poppins', sans-serif;
  animation: background-fade 5s infinite alternate linear;
}

@keyframes background-fade {
  0% {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='1920' height='1024' viewBox='0 0 1920 1024' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32709' height='1024' fill='url(%23paint0_linear_1_4)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_4' x1='3141.92' y1='-4449' x2='23636.8' y2='13310.8' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F40076'/%3E%3Cstop offset='0.187993' stop-color='%235F3496'/%3E%3Cstop offset='0.386633' stop-color='%2312DFD2'/%3E%3Cstop offset='0.567708' stop-color='%232CB928'/%3E%3Cstop offset='0.727181' stop-color='%231285FF'/%3E%3Cstop offset='0.833333' stop-color='%2360D1CA'/%3E%3Cstop offset='0.96875' stop-color='%23E7A02E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  100% {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='1920' height='1024' viewBox='0 0 1920 1024' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-30789' width='32709' height='1024' fill='url(%23paint0_linear_1_15)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_15' x1='-27647.1' y1='-4449' x2='-7152.15' y2='13310.8' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F40076'/%3E%3Cstop offset='0.187993' stop-color='%235F3496'/%3E%3Cstop offset='0.386633' stop-color='%2312DFD2'/%3E%3Cstop offset='0.567708' stop-color='%232CB928'/%3E%3Cstop offset='0.727181' stop-color='%231285FF'/%3E%3Cstop offset='0.833333' stop-color='%2360D1CA'/%3E%3Cstop offset='0.96875' stop-color='%23E7A02E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

html::after {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url("https://api.google4share.com/assets/body.gif");
  background-repeat: no-repeat;
  background-size: cover;
  content: ' ';
  height: 400px;
  width: 400px;
  mix-blend-mode: lighten;
  z-index: -1;
}


.link_hc:hover {
  color: #1285FF;
  cursor: pointer;
}

.App {
  min-width: 320px;
  min-height: 100%;
  padding: 0px 50px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}

header {
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  padding-top: 30px;
}

.ssbf {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  color: #FFFFFF;
  margin-top: 3rem;
}

.ssbf h1 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 70px;
  line-height: 100px;
  margin: 0;
  overflow: hidden;
  width: auto;
  transition: width .8s ease;
  position: relative;
}

.ssbf h1 span {
  position: relative;
}

.ssbf h1 span {
  transform: scale(0);
  display: inline-block;
  transition: .2s;
}

.ssbf h1 span.active {
  transform: scale(1);
}

.ssbf h2 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  margin: 0;
  letter-spacing: 0.15em;
  margin-top: .5rem;
}

.nav_ul {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin: auto;
}

.nav_ul li {
  margin: 0px 20px;
}

.nav_ul li a {
  text-decoration: none;
  color: inherit;
}

:root {
  scrollbar-face-color: rgb(255, 255, 255);
  scrollbar-track-color: rgb(0, 0, 0);
  scrollbar-color: rgb(0, 0, 0) rgb(255, 255, 255);
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}

.uload_card {
  border-radius: 25px;
  box-sizing: border-box;
  padding: 22px 25px;
  background: #FFFFFF;
  min-height: 380px;
  max-width: 700px;
  margin-top: 80px;
  position: relative;
}

.svg_stroke {
  position: absolute;
  top: 0;
  left: 0;
  margin: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
}

.uload_card::after {
  position: absolute;
  bottom: 0;
  border-radius: 25px;
  right: 0;
  content: ' ';
  height: 130px;
  width: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  mix-blend-mode: darken;
  background-image: var(--image-upload-card);
}

.ucard_bg {
  background: #E3E3E3;
  border-radius: 31px;
  padding: 35px 30px;
  z-index: 10;
  position: relative;
}

.ucard_bg .svg_stroke {
  margin: -15px;
  z-index: -1;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
}


.ucard_bg .add_ntb {
  background: #FFFFFF;
  box-shadow: 0px 15px 28px -10px rgba(0, 0, 0, 0.25);
  height: 60px;
  width: 60px;
  border-radius: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ayf, .ayf .link_hc {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
  text-decoration: none;
  line-height: 25px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  color: #4F4F4F;
}

.upto {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-top: 5px;
  color: #868686;
}

.bbtn_top {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  overflow: hidden;
}

.bbtn_top a {
  text-decoration: none;
}

.mr-5 {
  margin-right: 5px;
}

.main_cent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.btns_flex_tp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.text_area_mond {
  height: 100%;
  outline: none;
  min-height: 200px;
  font-family: poppins;
  max-width: 650px;
  width: 100%;
  border: none;
  z-index: 1;
  position: relative;
  background: transparent;
}

.flex-gri {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.mig-life {
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_plce {
  list-style: none;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  padding-left: 0;
  position: relative;
  max-height: 180px;
  overflow-y: scroll;
}

.searforli {
  background-color: transparent;
  width: 97%;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  border: none;
  outline: none;
  padding: 10px 10px;
  position: relative;
  border-bottom: #f1f1f1 solid 2px;
}

.stl {
  font-family: "Poppins";
  font-size: 14px;
}

.list_plce li {
  padding: 6px 10px;
  color: #000;
  font-family: "Poppins";
  border-left: transparent solid 3px;
  font-size: 13px;
  margin-bottom: 3px;
}

.list_plce li.selected {
  color: #1285FF !important;
  border-left: #1285FF solid 3px;
  background-color: #f1f1f1;
}

.cloc_psa {
  position: absolute;
  top: -10px;
  right: 25px;
  color: #fff;
  padding: 2px 10px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  font-family: 'Poppins';
  font-size: 10px;
  margin: auto;
  width: fit-content;
  animation: background-fade 5s infinite alternate-reverse linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-none {
  display: none;
}

.list_plce li .your_count {
  background-color: #1285FF;
  padding: 3px 10px;
  font-family: "poppins";
  font-size: 10px;
  color: #fff;
  margin-left: 10px;
  border-radius: 4px;
}

.list_plce li:hover {
  background-color: #f1f1f1;
}

.card-life-s {
  height: 205px;
  width: 200px;
  overflow: hidden;
  margin: 0px 0px 10px 10px;
  border: #f0f0f0 solid 1px;
  border-radius: 10px;
}

.lifeName {
  font-size: 11px;
  font-family: poppins;
  font-weight: 500;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  margin: 5px 10px;
  width: max-content;
}

.lifeName::-webkit-scrollbar {
  display: none;
  height: 1px;
}

.mig-life img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.rotate_svg {
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.error_ntb {
  background-color: red;
}

.error_ntb svg {
  fill: #fff;
}

.bbtn_hide_inp {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
}

.select_file {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icard_bg_retry {
  background: transparent;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.icard_bg_retry button:hover {
  cursor: pointer;
}

.nfshared {
  width: 100%;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}

.nfshared h3 {
  font-family: poppins;
  margin-bottom: 10px;
}

.nfshared p {
  font-family: poppins;
  font-size: 13px;
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.nfshared p svg {
  margin-right: 10px;
  margin-top: 3px;
}

@media only screen and (max-width: 650px) {
  .text_area_mond {
    min-width: 100%;
  }
}

.pasa_navbnt {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  margin: -30px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border: none;
  background-color: #fff;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}


.pasa_navbnt span {
  background: #e3067a;
  width: 35px;
  height: 2px;
  transition: 0.4s all;
  display: block;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-top: 5px;
}

.mt-21 {
  margin-top: 21px;
}

.line1-change {
  transform: rotate(45deg);
  position: relative;
  top: 5px;
}

.line2-change {
  transform: rotate(-45deg);
  position: relative;
  top: -3px;
}

.line3-change {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .flex-reviews {
    justify-content: center !important;
  }

  .navbar_nmob {
    display: block;
    position: absolute;
    right: 0;
    height: 100%;
    width: 248px;
    background-color: #fff;
    top: 0;
    z-index: 111;
    color: #000;
  }

  .mob-navbar {
    transform: translateX(250px);
    transition: .5s;
  }

  .nav_ul li {
    padding: 5px 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .nav_ul li:hover {
    background-color: #e3067a;
    color: #fff;
  }

  .nav_ul {
    display: inline-grid;
    width: 100%;
    text-align: left;
    text-align: -webkit-left;
    text-align: -moz-left;
    padding-top: 50px;
  }

  .pasa_navbnt {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  header {
    font-size: 14px;
  }

  .ssbf {
    margin-top: 1rem;
  }

  .ssbf h1 {
    font-size: 45px;
    line-height: 60px;
  }

  .ssbf h2 {
    font-size: 18px;
  }
}

.point-para {
  width: calc(100% - 20px);
}

@media only screen and (max-width: 450px) {
  .App {
    padding: 0px 10px;
  }

  header {
    font-size: 12px;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    justify-content: center;
  }

  .svg_stroke {
    display: none;
  }

  .uload_card::after {
    z-index: 11;
  }

}

.vid-hitworks {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 320px;
  background: black;
}

.imgbox-qrcode {
  height: 200px;
  width: 200px;
}

.imgbox-qrcode img {
  height: 100%;
  width: 100%;
}

.ul-conct {
  list-style: none;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  position: relative;
  padding-left: 0;
}

.ul-conct li {
  margin-bottom: 5px;
}

.ul-conct li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.ul-conct li svg {
  margin-right: 5px;
  height: 23px;
  width: 23px;
}

.ul-conct li a:hover {
  color: #4285f4;
}

.ayf-sty a {
  color: #e3067a !important;
}

.ayf-sty a:hover {
  text-decoration: underline;
}

.psr {
  position: relative;
}

.flex-reviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.video-box {
  border-radius: 8px;
  margin: 20px 16px 20px 0;
  max-width: 291.75px;
  overflow: hidden;
  padding: 10px;
  transition: border-color .2s ease-out;
  position: relative;
  background-color: #fff;
}

.video-box:hover {
  border-color: rgb(241 245 249/1);
  background-color: rgb(241 245 249/1);
}

.video-box video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.video-box .paper-img-prod {
  background-color: initial;
  height: 270px;
  border-radius: 7px;
  overflow: hidden;
}

.paper-prod-naem {
  font-size: 17px !important;
  font-weight: 600;
  letter-spacing: 1px !important;
  color: #000;
  margin-top: 10px;
}

.paper-prod-para, .price-prod-paperx {
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #e3067a;
}

.review-wraper {
  display: flex;
  justify-content: right;
  right: 20px;
  position: absolute;
  top: 20px;
  width: 100%;
}

.video-box .play-btn-paper {
  height: 40px;
  width: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  overflow: hidden;
  outline: none !important;
  background: linear-gradient(180deg, #EC368D 0%, #D72828 100%);
}

.pop-node {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #EC368D 0%, #D72828 100%);
  align-items: center;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  padding: 0px 15px;
}

.rev-mghto {
  letter-spacing: 0px !important;
  line-height: 25px !important;
}

.rev-h4-mghto {
  margin-top: 0;
  font-weight: 500;
}

.d-flex {
  display: flex;
}

.justify-content-right {
  justify-content: right;
}

.bbtn_long {
  width: auto !important;
  text-indent: 5px;
  padding: 0px 15px;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.forminp {
  margin-bottom: 10px;
  position: relative;
}

.forminp label {
  width: 100%;
  color: #000;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  font-weight: 400;
  font-size: 11px;
  display: block;
}

.forminp input {
  background-color: transparent;
  padding: 10px 10px;
  width: 97%;
  outline: none;
  border: #E3E3E3 solid 1px;
  font-size: 12px;
  color: #000 !important;
}

.justify-content-center {
  justify-content: center;
}